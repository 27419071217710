import "./styles/quasar.sass";
import iconSet from "quasar/icon-set/fontawesome-v5";

import lang from "quasar/lang/de.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";

import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: { notify: {} },
  plugins: { Notify },
  iconSet: iconSet,
  lang: lang,
};
