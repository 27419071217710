<template>
  <div id="harharhar" class="content">
    <h1 class="q-mb-md">Förderprogramm</h1>
    <h2 class="q-mt-md">unternehmensWert:Mensch plus</h2>

    <p>
      Das Förderprogramm unternehmensWert:Mensch richtet sich an kleine und
      mittelgroße Unternehmen (KMU), um diese beim Übergang von der Industrie-
      zur Wissensgesellschaft zu unterstützen. Durch die Nutzung moderner
      Managementkonzepte sollen Unternehmen fit für den erfolgreichen
      Transformationsprozess gemacht werden, um so Innovationskraft und
      Leistungsfähigkeit zu steigern. Einsatz von webbasierten Technologien,
      moderne Führungssysteme sowie Wissens- und Kompetenzmanagement sind
      typische Fragestellungen, bei denen wir Ihnen helfen, den Kompass neu
      auszurichten und die Segel in Richtung Zukunft erfolgreich zu setzen.
    </p>
    <p>
      Das Programm unternehmensWert:Mensch plus fokussiert auf die Themen
      digitaler Wandel und strategische Innovationen. Durch den systematischen
      und straff organisierten Beratungsprozess, der agile Managementkonzepte
      aufgreift, lernen Ihre Beschäftigten ‚on the job‘ diese neue Art der
      Arbeit kennen. Dies hat den Vorteil, dass man nicht auf einer
      theoretischen Ebene verharrt, sondern direkt in die Umsetzung geht und das
      an Ergebnissen orientierte Projektmanagement verinnerlicht und auf auch
      auf andere Fragestellungen anwendet (<a
        href="https://inqa.de/DE/handeln/inqa-beratung/tom-und-sallys.html"
        >lesen hierzu auch das Interview von unserem Kunden Tom & Sally’s</a
      >).
    </p>
    <h4 class="text-center text-bold">
      Sichern Sie sich 9.600 EUR vom Staat für Digitalisierungsberatung.
    </h4>
    <p>
      Die Bundesregierung möchte KMU bei der notwendigen Transformation
      finanziell unterstützen und übernimmt in diesem Programmschwerpunkt 80%
      der Beratungskosten, um damit auch KMU den Zugang zu professioneller
      Beratung zu erleichtern.
    </p>
    <p>
      Weitere Informationen finden Sie im Programm-Flyer:
      <a href="../../assets/Flyer_uWM_plus.pdf">Download Flyer uWM plus</a>
    </p>
    <h2>Beratungsschwerpunkte. Was gefördert wird.</h2>
    <p>
      Im Förderprogramm können verschiedene Themenfelder gewählt werden.
      Basierend auf unserer Erfahrung, empfehlen wir, den inhaltlichen Fokus auf
      die Bereiche Neue Geschäftsmodelle & Innovationsstrategien sowie
      Produktionsmodell & Arbeitsorganisation zu legen.
    </p>
    <h3>Neue Geschäftsmodelle & Innovationsstrategien</h3>
    <p>
      Leitfrage: Wie verändern sich Marktbedingungen durch den digitalen Wandel
      und wie müssen wir darauf reagieren, um nicht hinter den Wettbewerb
      zurückzufallen?
    </p>
    <p>
      Dieser Themenkomplex bezieht auf die Marktseite Ihres Unternehmens. Firmen
      und Betriebe müssen sich fragen, welche Bedeutung der digitale Wandel auf
      das eigene Unternehmen hat. Einerseits sollte geprüft werden, inwieweit
      digitale Lösungen für das eigene Angebot relevant werden (bspw. durch
      zusätzliche Services, durch die Umsatzpotentiale gehalten oder erschlossen
      werden können).
    </p>
    <ul>
      <li>
        Welche Marktentwicklungen (bspw. Zugang zu Informationen, Nachhaltigkeit
        etc.) und Technologien (bspw. Clouddienste, neue digitale
        Kommunikations- und Koordinationstools etc.) werden für unser Handeln
        relevant und wie müssen wir darauf reagieren?
      </li>
      <li>
        Welche digitalen Geschäftsmodelle gibt es und wie kann Ihre Firma davon
        profitieren?
      </li>
      <li>
        Wie können wir durch Innovationen unser Unternehmen neu positionieren,
        damit es wettbewerbsfähig bleibt?
      </li>
    </ul>
    <h3>Produktionsmodell & Arbeitsorganisation</h3>
    <p>
      Leitfrage: Welche Effizienzsteigerung kann durch Digitalisierung in
      Produktion und Arbeitsorganisation realisiert werden?
    </p>
    <p>
      Durch digitale Lösungen bieten sich viele Möglichkeiten, um
      Effizienzsteigerungen zu realisieren. Im Kern geht es darum, die richtige
      Information, zum richtigen Zeitpunkt zur Verfügung zu stellen. So können
      bspw. Produktionsprozesse besser abgestimmt, eine höhere Erreichbarkeit
      durch neue Kommunikationswege erzielt oder die Schichtplanung schnell und
      stimmig erledigt werden, um nur einige Bereiche zu nennen.
    </p>
    <p>
      Steigern Sie die Produktivität und stärken Sie Ihre Wettbewerbsfähigkeit
      durch den Einsatz neuer Technologien.
    </p>
    <ul>
      <li>Welche Technologien sind für mein Unternehmen relevant?</li>
      <li>
        In welchen Bereichen meines Unternehmens kann ich durch den Einsatz von
        digitalen Lösungen Effizienzzugewinne realisieren?
      </li>
      <li>
        Welche konkreten Anbieter von digitalen Tools wähle ich (Google oder
        Microsoft, Asana oder Microsoft Project) wähle final aus und wie binde
        ich Beschäftigte bestmöglich ein?
      </li>
    </ul>
    <h2>Zielgruppe. Wer gefördert wird.</h2>
    <p>Gefördert werden KMU, die folgende Kriterien erfüllen:</p>
    <ul>
      <li>Sitz und Arbeitsstätte in Deutschland</li>
      <li>Seit mindestens zwei Jahren am Markt</li>
      <li>
        Jahresumsatz weniger als 50 Millionen Euro oder Jahresbilanzsumme
        geringer als 43 Millionen Euro
      </li>
      <li>
        Weniger als 250 Beschäftigte (Vollzeitäquivalent; falls Teilzeitkonzepte
        genutzt werden, können es auch mehr als 250 Beschäftigte sein)
      </li>
      <li>
        Anzahl Personen: min. 5 (insgesamt müssen im Rahmen des Programmzweig
        unternehmensWert:Mensch plus mindestens fünf Personen in Ihrer Firma
        oder mit Ihnen arbeiten, damit alle relevanten Projektrollen besetzt
        werden können.)
      </li>
      <li>
        Mindestens eine/n sozialversicherungspflichtige/n Beschäftigte/n in
        Vollzeit (Die Berechnung erfolgt nach Jahresarbeitseinheiten.
        Teilzeitbeschäftigte können anteilig berücksichtigt werden.)
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Foerderprogramm",
};
</script>
