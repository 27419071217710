import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import router from "./router";
import store from "./store";

import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import matomoUserOptions from "./matomo-user-options";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMatomo from "vue-matomo";

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueMatomo, matomoUserOptions)
  .mount("#app");
