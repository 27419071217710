<template>
  <q-page>
    <div
      class="fit row wrap justify-center items-start content-start"
      style="color: white; background-color: black"
    >
      <div class="title-sheet">
        <h2 class="text-h2 orange">Martin Lacroix</h2>
        <h1 class="text-h1 downlow uptop primary" style="font-weight: 800">
          DIGITALISIERUNG
        </h1>
        <h2 class="text-h2 uptop" style="margin-top: 0">einfach. schnell.</h2>
      </div>
    </div>
    <div class="row" id="kontakt" style="padding: 2rem 3rem 0 3rem">
      <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
        <h2 class="text-h2 primary" style="font-weight: 800">
          Der Staat fördert Digitalisierungsprozesse!!!
        </h2>
        <h3>
          Kostenübernahme von 80 % durch das Ministerium für Arbeit bis zum
          31.05.2022 sichern.
        </h3>
      </div>
    </div>

    <HomeKontakt />

    <BerichteAusDerPraxis />

    <MotivationsBloecke />

    <Foerderprogramm id="foerderprogramm" />

    <div class="content">
      <div class="fit row wrap justify-center">
        <div>
          <q-btn
            color="white"
            type="a"
            href="#kontakt"
            v-on:click.prevent="scrollPageTo('kontakt')"
            text-color="black"
            icon-right="keyboard_arrow_up"
            label="Informieren Sie sich jetzt"
            :ripple="{ center: true }"
          />
        </div>
      </div>
    </div>

    <!-- place QPageScroller at end of page -->
    <q-page-scroller
      position="bottom-right"
      :scroll-offset="450"
      :offset="[18, 18]"
    >
      <q-btn fab icon="keyboard_arrow_up" color="primary" />
    </q-page-scroller>
  </q-page>
</template>

<script>
// @ is an alias to /src
import BerichteAusDerPraxis from "../components/Home/BerichteAusDerPraxis";
import MotivationsBloecke from "../components/Home/MotivationsBloecke";
import HomeKontakt from "../components/Home/HomeKontakt";
import Foerderprogramm from "../components/Home/Foerderprogramm";

export default {
  name: "Home",
  components: {
    Foerderprogramm,
    HomeKontakt,
    MotivationsBloecke,
    BerichteAusDerPraxis,
  },
  props: {},
  data() {
    return {
      // formDisabled: false,
    };
  },
  setup() {
    const scrollPageTo = (navEl) => {
      // console.log(`#${navEl}`);
      let element = document.querySelector(`#${navEl}`);
      // console.log(element);
      element.scrollIntoView({ behavior: "smooth" });
    };

    return {
      scrollPageTo,
    };
  },
};
</script>

<style scoped>
.bg-alternate-blue {
  background-color: #3293c2;
}

.title-sheet {
  padding: 10vh 0 20vh 0;
}

.downlow {
  margin-bottom: 0;
  padding-bottom: 0;
}
.uptop {
  margin-top: 1.5rem;
  padding-top: 0;
}
</style>
