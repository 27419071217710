import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const PAGE_TITLE = "Martin Lacroix";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/impressum",
    name: "Impressum",
    meta: {
      title: "Impressum",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/Impressum.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: {
      title: "Datenschutz",
    },
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (typeof to.meta !== "undefined" && typeof to.meta.title !== "undefined") {
    document.title = PAGE_TITLE + " | " + to.meta.title;
  } else {
    document.title = PAGE_TITLE;
  }
  next();
});

export default router;
