<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <router-view />
    </q-page-container>

    <q-footer bordered class="bg-grey-9 text-white">
      <q-toolbar>
        <div class="full-width row">
          <div class="col text-center">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </div>
          <div class="col text-center">
            <router-link :to="{ name: 'Impressum' }">Impressum</router-link>
          </div>
          <div class="col text-center">
            <router-link :to="{ name: 'Privacy' }">Datenschutz</router-link>
          </div>
        </div>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>
//nothing to do
</script>

<style scoped></style>
