<template>
  <div>
    <div class="bg-alternate-blue">
      <div class="row fit q-col-gutter-x-xl reverse-wrap content">
        <div class="col-md-6 col-sm-12">
          <img src="@/assets/welt-tickt-digital.jpg" style="max-width: 100%" />
        </div>
        <div class="col-md-6 col-sm-12">
          <h3 class="q-mt-none q-mb-md">
            Die Welt tickt digital.<br />Ihr Unternehmen auch?
          </h3>
          <p>
            Produkte und Geschäftsbeziehungen werden zunehmend digitalisiert. Um
            im Wettbewerb nicht zurückzufallen, müssen Unternehmen sich aktiv
            mit den Möglichkeiten der Digitalisierung auseinandersetzen. Wir
            unterstützen Sie bei diesem Prozess. Einfach und schnell.
          </p>
          <p>
            <a
              href="#foerderprogramm"
              v-on:click.prevent="scrollPageTo('foerderprogramm')"
              >Erfahren Sie mehr zu den Fördermöglichkeiten
              <q-icon name="keyboard_arrow_down"
            /></a>
          </p>
        </div>
      </div>
    </div>
    <div class="row fit q-col-gutter-x-xl content">
      <div class="col-md-6 col-sm-12">
        <h3 class="q-mt-none q-mb-md">Den richtigen Zugang finden.</h3>
        <p>
          Die Digitalisierung stellt für viele KMU eine echte Herausforderung
          dar. Wir unterstützen Sie, den richtigen Zugang zu finden und zwar mit
          Methode. Wir machen Sie fit für die digitale Transformation.
        </p>
        <!-- <p>Erfahren Sie mehr über die Methodik des Beratungsprozesses.</p>-->
      </div>
      <div class="col-md-6 col-sm-12">
        <img src="@/assets/zugang-1.jpg" style="max-width: 100%" />
      </div>
    </div>
    <div class="bg-alternate-blue">
      <div class="fit row q-col-gutter-x-xl reverse-wrap content">
        <div class="col-md-6 col-sm-12">
          <img src="@/assets/agiles-mindset-2.jpg" style="max-width: 100%" />
        </div>
        <div class="col-md-6 col-sm-12">
          <h3 class="q-mt-none q-mb-md">Agiles Mindset entwickeln.</h3>
          <p>
            Um Ihr Unternehmen erfolgreich zu transformieren, benötigen Sie das
            richtige Mindset. Wie bekommen Sie das? Mit Methode: Projekt-Sprints
            erhöhen das Tempo. Das Projekt wird zum Trainingscamp, bei dem die
            Methode auch Haltung und Herangehensweise transformiert.
          </p>
          <p>
            <a
              href="https://inqa.de/DE/handeln/inqa-beratung/tom-und-sallys.html"
              >Erfahren Sie mehr darüber, wie wir Tom & Sally's unterstützen
              konnten. <q-icon icon="fa-external-link-alt" color="#000"
            /></a>
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="row fit q-col-gutter-x-xl content">
      <div class="col-md-6 col-sm-12">
        <h3 class="q-mt-none">
          Durch Partizipation den digitalen Wandel verstetigen
        </h3>
        <p>
          Durch ein Projekt digitalisieren Sie noch nicht Ihr ganzes
          Unternehmen. Durch die aktive Einbindung erlernen Beschäftigte neue
          Kompetenzen, die für die weitere Digitalisierung wichtig sind. Das
          zeigt die Praxis. Neben der methodischen Begleitung ist „Learning by
          doing“ der wichtigste Erfolgsfaktor. Dadurch wird Agilität im
          Unternehmen verstetigt und trägt langfristig zum Erfolg bei. Ganz
          nebenbei eröffnen Sie den Beschäftigten spannende
          Entwicklungsmöglichkeiten, die zu einer stärken Bindung an ihr
          Unternehmen führen. In Zeiten des Fachkräftemangels ein nicht ganz
          urerheblicher Seiteneffekt.
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <img src="@/assets/partizipation.jpg" style="max-width: 100%" />
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "MotivationsBloecke",
  setup() {
    const scrollPageTo = (navEl) => {
      // console.log(`#${navEl}`);
      let element = document.querySelector(`#${navEl}`);
      // console.log(element);
      element.scrollIntoView({ behavior: "smooth" });
    };

    return {
      scrollPageTo,
    };
  },
};
</script>
<style scoped>
.bigpadding {
  padding: 3rem;
}

.bg-alternate-blue {
  background-color: #3293c2;
}
</style>
