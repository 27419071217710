<template>
  <div class="row justify-center" style="margin: 0 3rem 6rem 3rem">
    <div
      class="col-md-12 col-lg-12 col-xs-12 col-sm-12"
      style="max-width: 800px"
    >
      <h5>Informieren Sie sich jetzt:</h5>
      <q-form @submit="onSubmit" class="q-gutter-md">
        <q-input
          filled
          :disable="formDisabled"
          v-model="name_field"
          label="Ihr Name *"
          hint="Vor- und Nachname"
          lazy-rules
          :rules="[
            (val) =>
              (val && val.length > 0) || 'Bitte füllen Sie dieses Feld aus',
          ]"
        />
        <q-input
          filled
          :disable="formDisabled"
          v-model="firma"
          label="Firma / Organisation *"
          hint="Firmen- oder Organisationsname"
          lazy-rules
          :rules="[
            (val) =>
              (val && val.length > 0) || 'Bitte füllen Sie dieses Feld aus',
          ]"
        />
        <q-input
          filled
          :disable="formDisabled"
          v-model="telefon"
          label="Telefon *"
          hint="Ihre Telefonnummer"
          lazy-rules
          :rules="[
            (val) =>
              (val && val.length > 0) || 'Bitte füllen Sie dieses Feld aus',
          ]"
        />
        <q-input
          filled
          :disable="formDisabled"
          v-model="email"
          type="email"
          label="E-Mail *"
          hint="Ihre E-Mailadresse"
          lazy-rules
          :rules="[
            (val) =>
              (val && val.length > 0) || 'Bitte füllen Sie dieses Feld aus',
          ]"
        />

        <q-toggle
          v-model="accept"
          :disable="formDisabled"
          label="Ich habe die Datenschutzbestimmungen zur Kenntnis genommen."
        />

        <div>
          <q-btn
            label="Absenden"
            type="submit"
            color="primary"
            :disable="formDisabled"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>
<script>
import { useQuasar } from "quasar";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "HomeKontakt",
  setup() {
    const $q = useQuasar();
    const email = ref(null);
    const telefon = ref(null);
    const firma = ref(null);
    const name_field = ref(null);
    const accept = ref(false);
    const formDisabled = ref(false);
    return {
      email,
      name_field,
      telefon,
      firma,
      accept,
      formDisabled,
      onSubmit() {
        if (accept.value !== true) {
          $q.notify({
            color: "red-5",
            textColor: "white",
            icon: "warning",
            message:
              "Bitte bestätigen Sie, dass Sie unsere Datenschutzbestimmungen zur Kenntnis genommen haben.",
          });
        } else {
          formDisabled.value = true;
          $q.notify({
            color: "green-4",
            textColor: "white",
            icon: "cloud_done",
            message: "Vielen Dank! Wir melden uns in Kürze bei Ihnen.",
          });
          const payload = {
            email: email.value,
            name_field: name_field.value,
            telefon: telefon.value,
            firma: firma.value,
            accept: accept.value,
            origin: "martin-lacroix.de",
            "info-anfordern-botcheck": "",
            subject: "[martin-lacroix.de HP] Infos zur Beratung erwuenscht",
            html_title: "[martin-lacroix.de HP] Infos zur Beratung erwuenscht",
            prefix: "info-anfordern-",
          };
          const data = new FormData();
          for (let key of Object.keys(payload)) {
            data.append(key, payload[key]);
          }
          axios.post("/cgi-bin/form.php", data, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        }
      },
    };
  },
};
</script>
