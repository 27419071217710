<template>
  <div class="row fit q-mb-xl">
    <q-parallax src="@/assets/whiteboard-ausschnitt.jpg">
      <q-card style="max-width: 550px" flat bordered>
        <q-card-section horizontal>
          <q-card-section class="q-pt-xs">
            <div class="text-overline">Bericht aus der Praxis</div>
            <div class="text-h5 q-mt-sm q-mb-xs">
              Personaleinsatzplanung digital
            </div>
            <div class="text-caption text-grey">
              <a
                href="https://inqa.de/DE/handeln/inqa-beratung/tom-und-sallys.html"
                target="_blank"
                >Lesen Sie hier, wie wir Tom & Sally's bei der Digitalisierung
                des Unternehmens unterstützen konnten.
                <i class="fas fa-external-link-alt"></i
              ></a>
            </div>
            <div class="q-mt-md q-mb-xs">
              <q-btn
                color="primary"
                type="a"
                href="https://inqa.de/DE/handeln/inqa-beratung/tom-und-sallys.html"
                target="_blank"
                icon-right="fas fa-external-link-alt"
                >Zum Interview auf uWM plus&nbsp;&nbsp;
              </q-btn>
            </div>
          </q-card-section>
          <q-card-section class="col-5 flex flex-center">
            <q-img class="rounded-borders" src="@/assets/tom-sallys.png" />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-parallax>
  </div>
</template>
<script>
export default {
  name: "BerichteAusDerPraxis",
};
</script>
